.slider-wrapper {
  /* margin: 1rem; */
  position: relative;
  overflow: hidden;

  border: 1px solid #eee;
  padding: 30px;
  width: 100%;
  max-width: 650px;
  border-radius: 5px;
  box-sizing: border-box;

  height: 56vh;
  margin-bottom: 8em;
}

.slides-container {
  height: calc(100vh - 2rem);
  width: 100%;
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  overflow: scroll;
  scroll-behavior: smooth;
}

.slide {
  /* width: 100%; */
  /* height: 100%; */
  flex: 1 0 100%;
  width: 50%;
  height: 50%;
  object-fit: contain;
}
.slides-container {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
}

/* WebKit */
.slides-container::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.slide-arrow {
  position: absolute;
  display: flex;
  top: 0;
  bottom: 0;
  margin: auto;
  height: 4rem;
  background-color: white;
  border: none;
  width: 2rem;
  font-size: 3rem;
  padding: 0;
  cursor: pointer;
  opacity: 0.5;
  transition: opacity 100ms;
}

.slide-arrow:hover,
.slide-arrow:focus {
  opacity: 1;
}

#slide-arrow-prev {
  left: 0;
  padding-left: 0.25rem;
  border-radius: 0 2rem 2rem 0;
}

#slide-arrow-next {
  right: 0;
  padding-left: 0.75rem;
  border-radius: 2rem 0 0 2rem;
}

@media only screen and (max-width: 600px) {
  .slider-wrapper {
    height: 50vh;
    margin-bottom: 0;
  }
  .dot-container {
    margin-top: -2.3em !important;
  }
}

.dot {
  width: 10px;
  height: 10px;
  margin-left: 20px;
  background-color: gray;
  border-radius: 50%;
  cursor: pointer;
}
.dot:hover {
  background-color: black;
}
.dot-container {
  display: flex;
  position: absolute;
  margin-top: -9.3em;
  width: -webkit-fill-available;
  justify-content: center;
}
