.callpage-container {
  .video-container {
    height: 100vh;
    width: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }
}
