.emoji_viewer {
  position: fixed;
  bottom: 6em;
  display: grid;
  margin-left: 11px;
  grid-auto-flow: row;
  grid-column-gap: 10px;
  grid-row-gap: 11px;
  .emoji_item {
    width: 30px;
    height: 30px;
    -webkit-animation: fadeinout 4s linear forwards;
    animation: fadeinout 4s linear forwards;
  }
}
@-webkit-keyframes fadeinout {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@keyframes fadeinout {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}
