.header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 20px;
  box-sizing: border-box;
}
.header .logo {
  display: flex;
  align-items: center;
  width: 45px;
  cursor: pointer;
}
.header .profile_image {
  border-radius: 50%;
  cursor: pointer;
}
.header .logo .helpText {
  font-size: 22px;
  padding-left: 4px;
  color: #5f6368;
}
.header .logo img {
  width: 100%;
}
.header .actionBtn {
  display: flex;
  align-items: center;
}
.header .actionBtn .iconBlock {
  font-size: 30px;
  padding: 10px;
  color: #5f6368;
}
