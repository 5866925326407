:root {
  --primary-color: #00996b;
  --dark-color: #00685c;
}

* {
  padding: 0;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.green {
  background: #00996b !important;
  color: #fff !important;
  font-weight: 500 !important;
}
.green :hover {
  background: #00685c !important;
}

.MuiLinearProgress-barColorPrimary {
  background-color: #04796a !important;
}
.MuiLinearProgress-colorPrimary {
  background-color: #04796a29 !important;
}
.MuiSnackbarContent-root {
  background-color: white !important;
  color: black !important;
}
.centered-div {
  position: absolute;
  width: 300px;
  height: 200px;
  z-index: 15;
  top: 50%;
  background: white;
  left: 50%;
  margin: -100px 0 0 -150px;
  text-align: center;
  box-shadow: 10px 10px 10px #d1d9e6, -10px -10px 10px #f9f9f9;
}
