.frame_header {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 350px;
  position: absolute;
  top: 0;
  right: 0;
  background: #fff;
  border-radius: 0 0 0 10px;
  overflow: hidden;
  .header_items {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    flex: 1;
    border-right: 1px solid rgb(245, 245, 245);
    position: relative;
    .badge {
      display: inline-block;
      /* min-width: 1em; */
      /* padding: .3em; */
      /* height: 17px; */
      border-radius: 56%;
      /* font-size: 75px; */
      text-align: center;
      background: #04796a24;
      /* color: #fefefe; */
      padding-left: 5px;
      padding-right: 5px;
      margin-top: -20px;
    }
    .alert_circle_icon {
      position: absolute;
      background: #04796a;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      border: 2px solid #fff;
      top: 10px;
      right: 20px;
    }
    &.date_block {
      font-size: 22px;
      color: #5f6368;
      min-width: 120px;
      font-weight: 400;
    }
    &.icon_block {
      .icon {
        color: #505357;
        font-size: 22px;
        &.profile {
          font-size: 32px;
          color: #04796a;
        }
      }
      &:hover {
        cursor: pointer;
        background: #eee;
      }
    }
  }
}
