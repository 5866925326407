.main_screen {
  width: 100%;
  height: 90vh;
  background: #202124;
}
/* #3c4043; */
.wrapper {
  width: 100%;
}

.footer {
  height: 10vh;
}
